import React from 'react'
import { useEffect } from 'react'
import CardDownload from '../../Components/CardDownload'
import Contact from '../../Components/Contact'
import SecondaryBanner from '../../Components/SecondaryBanner'

const Notice = () => {
  useEffect(() => {
    document.title = 'Notice'
    window.scrollTo(0, 0)
  }, [])
  const bannerData = {
    breadcrumb: ['Home', 'Notice'],
    bannerTitle: 'Notice',
  }
  const cardData = [
    {
      id: 1,
      title:
        'तपसिलमा उल्लेखित ऋणीहरुलाई कर्जा चुक्ता गर्न आउने ३५ दिने अत्यन्त जरूरी सूचना - 2078-11-13',
      link:
        'https://gurkhasfinance.com.np/assets/notice/35 Days notice-ManBdr_ Rupa, Fani Lal, Trimurti 35 Days Notice - 2078-11-13.pdf',
      year: '',
    },
    {
      id: 2,
      title:
        'तपसिलमा उल्लेखित ऋणीलाई कर्जा चुक्ता गर्न आउने ३५ दिने अतय्न्तै जरुरी सूचना',
      link:
        'https://gurkhasfinance.com.np/assets/notice/35 Days notice-Hemaj Maching Center-Mangsir 3, 2078.pdf',
      year: '',
    },
    {
      id: 3,
      title: '35 Days Notice - Bhadra 22, 2078',
      link:
        'https://gurkhasfinance.com.np/assets/notice/35 Days notice-Gurkhas finance-final 11- Bhadra 22, 2078.pdf',
      year: '',
    },
    {
      id: 4,
      title: 'Gair Banking Sampati Bikri - Baishak 8, 2077',
      link:
        'https://gurkhasfinance.com.np/assets/notice/Gair Banking notice-Gurkhas Finance-final(1).jpg',
      year: '',
    },
    {
      id: 5,
      title: 'Gair Banking Sampati Bikri - Baishak 5, 2080',
      link:
        'https://gurkhasfinance.com.np/assets/notice/Final-Gair Baniking notice-Gurkhas finance.pdf',
      year: '',
    },

    {
      id: 6,
      title: 'Gair Banking Notice - Kartik 21, 2080',
      link:
        'https://gurkhasfinance.com.np/assets/notice/Gair Baniking notice- 2080-07-21.pdf',
      year: '',
    },
    {
      id: 7,
      title: 'Gair Banking Notice - Magh 11, 2080',
      link:
        'https://gurkhasfinance.com.np/assets/notice/Gair Baniking notice- 2080-10-11.pdf',
      year: '',
    },
    {
      id: 8,
      title: '35 Days notice-Gurkhas finance-Baishak 23, 2081',
      link:
        'https://gurkhasfinance.com.np/assets/notice/35 Days Notice 2081-01-23.pdf',
      year: '',
    },
    {
      id: 9,
      title: 'Gair Banking Sampati Bikri - Baishak 14, 2081',
      link:
        'https://gurkhasfinance.com.np/assets/notice/NBA Sale Notice 2081-01-14.pdf',
      year: '',
    },
    {
      id: 10,
      title: '35 Days notice-Gurkhas finance-Baishak 27, 2081',
      link:
        'https://gurkhasfinance.com.np/assets/notice/35 Days Notice 2081-01-27.pdf',
      year: '',
    },
    {
      id: 11,
      title: '35 Days Notice 2081-02-04',
      link:
        'https://gurkhasfinance.com.np/assets/notice/35 Days Notice 2081-02-04-3.pdf',
      year: '',
    },
    {
      id: 12,
      title: '15 Days Notice-Final 2081-03-20',
      link:
        'https://gurkhasfinance.com.np/assets/notice/15 Days Notice-Final 2081-03-20.pdf',
      year: '',
    },
    {
      id: 13,
      title: '35 Days Notice 2081-03-21',
      link:
        'https://gurkhasfinance.com.np/assets/notice/35 -Days Notice 2081.03.21.pdf',
      year: '',
    },
    {
      id: 14,
      title: '35 Days Notice 2081-05-05',
      link:
        'https://gurkhasfinance.com.np/assets/notice/35 Days Notice 2081.05.05.pdf',
      year: '',
    },
    {
      id: 15,
      title: '35 Days Notice 2081-05-07',
      link:
        'https://gurkhasfinance.com.np/assets/notice/35 Days Notice 2081.05.07.pdf',
      year: '',
    },
    {
      id: 16,
      title: '15 Days Notice 2081-05-12',
      link:
        'https://gurkhasfinance.com.np/assets/notice/15 Days Notice 2081-05-12.pdf',
      year: '',
    },
     {
      id: 17,
      title: '15 Days Notice 2081-05-18',
      link:
        'https://gurkhasfinance.com.np/assets/notice/15 Days Notice 2081-05-18.pdf',
      year: '',
    },
    {
      id: 18,
      title: '35 Days Notice 2081-07-08',
      link:
        'https://gurkhasfinance.com.np/assets/notice/35 Days_Notice 2081-07-08.pdf',
      year: '',
    },
    {
      id: 19,
      title: '35 Days Gold Loan Notice 2081-09-18',
      link:
        'https://gurkhasfinance.com.np/assets/notice/35 Days Gold Loan Notice 2081-09-18.pdf',
      year: '',
    },
    {
      id: 20,
      title: '35 Days Notice 2081-09-18',
      link:
        'https://gurkhasfinance.com.np/assets/notice/35 Days Notice 2081-09-18.pdf',
      year: '',
    },
    {
      id: 21,
      title: '15 Days Notice 2081-10-09',
      link:
        'https://gurkhasfinance.com.np/assets/notice/15 Days Notice 2081-10-09.pdf',
      year: '',
    },
    {
      id: 22,
      title: '35 Days Notice 2081-10-09',
      link:
        'https://gurkhasfinance.com.np/assets/notice/35 Days Notice 2081-10-09.pdf',
      year: '',
    },
    
    
  ]
  const officialCardData = [
    {
      id: 1,
      title:
        'गोर्खाज फाइनान्स लिमिटेडका सम्पूर्ण खातावाला ग्राहक महानुभावहरुलाई अत्यन्त जरुरी सूचना',
      link:
        'https://gurkhasfinance.com.np/assets/notice/kyc update 11 2078-11-4.jpg',
      year: '',
    },
    {
      id: 2,
      title: 'Deactivate Account',
      link:
        'https://gurkhasfinance.com.np/assets/notice/Ten Years-Feb 16, 2022.pdf',
      year: '',
    },
    {
      id: 3,
      title: 'Contact List for Lockdown Information',
      link: 'https://gurkhasfinance.com.np/assets/notice/corona.png',
      year: '',
    },
    {
      id: 4,
      title: 'संचालक निर्वाचन कार्यक्रमको सूचना April 25, 2021',
      link: 'https://gurkhasfinance.com.np/assets/notice/new.png',
      year: '',
    },
    // {
    //   id: 5,
    //   title: 'छब्बीसाैँ वार्षिक साधारणसभा सम्बन्धी सूचना April 11, 2021',
    //   link: 'https://gurkhasfinance.com.np/assets/notice/guf.jpg',
    //   year: '',
    // },
    {
      id: 6,
      title: 'गैर बैंबिङ्ग सम्पत्ति बिक्री सम्बन्धी​ Mar 26, 2021',
      link:
        'https://gurkhasfinance.com.np/assets/notice/Gair Banking notice-Gurkhas Finance-final.jpg',
      year: '',
    },
    {
      id: 7,
      title: 'DMAT Service Mar 26, 2021',
      link: 'https://gurkhasfinance.com.np/assets/notice/dmat.pdf',
      year: '',
    },
    {
      id: 8,
      title: 'Information regarding Branchless Banking(BLB)',
      link:
        'https://gurkhasfinance.com.np/assets/notice/Information regarding Branchless Banking(BLB).pdf',
      year: '',
    },
    {
      id: 9,
      title: 'Notice regarding CRN of Foreign Employment',
      link:
        'https://gurkhasfinance.com.np/assets/notice/Notice regarding CRN of Foreign Employment.pdf',
      year: '',
    },
    {
      id: 10,
      title: 'Information Regarding Citizen Saving Bond',
      link:
        'https://gurkhasfinance.com.np/assets/notice/Information Regarding Citizen Saving Bond.pdf',
      year: '',
    },

    {
      id: 11,
      title: 'Share Conversion Notice',
      link:
        'https://gurkhasfinance.com.np/assets/notice/Share Conversion Notice -Gurkhas finance.pdf',
      year: '',
    },
    {
      id: 12,
      title: 'दश वर्षदेखि शून्य मौज्दात रही कारोबार नभएका निक्षेप खाताहरुको सूची',
      link:
        'https://gurkhasfinance.com.np/assets/notice/दश वर्षदेखि शून्य मौज्दात रही कारोबार नभएका निक्षेप खाताहरुको सूची.pdf',
      year: '',
    },
    {
      id: 13,
      title: 'Request for Proposal-Gurkhas finance',
      link:
        'https://gurkhasfinance.com.np/assets/notice/Request for Proposal-Gurkhas finance.pdf',
      year: '',
    },
    {
      id: 14,
      title: 'दश वर्ष देखि शून्य मौज्दात रही कारोबार नभएका निक्षेप खाताहरु बन्द गरिने सम्बन्धी सूचना',
      link:
        'https://gurkhasfinance.com.np/assets/notice/दश वर्ष देखि शून्य मौज्दात रही कारोबार नभएका निक्षेप खाताहरु बन्द गरिने सम्बन्धी सूचना.jpg',
      year: '',
    },
    
    
  ]
  const AGMNotice = [
    {
      id: 1,
      title:
        'वार्षिक साधारणसभा सम्बन्धी सूचना - २०८०/११/१० ',
      link:
        'https://gurkhasfinance.com.np/assets/notice/gurkhas_agm_notice.pdf',
      year: '',
    },
    {
      id: 2,
      title:
        'वार्षिक साधारणसभा सम्बन्धि सूचना २०८१-०९-०८',
      link:
        'https://gurkhasfinance.com.np/assets/notice/वार्षिक साधारणसभा सम्बन्धि सूचना २०८१-०९-०८.pdf',
      year: '',
    },
  ]
  return (
    <main>
      <SecondaryBanner bannerData={bannerData} />
      <section className="section-report">
        <div className="container" style={{ backgroundColor: 'white' }}>
          <div className="header-box" style={{ padding: '20px 0 0 55px' }}>
            <p className="title">Vendor/Auction</p>
          </div>
          <div className="card-row" style={{ padding: '25px 43px 70px 47px' }}>
            {cardData.map((card) => (
              <CardDownload
                cardTitle={card.title}
                link={card.link}
                key={card.id}
              />
            ))}
          </div>
        </div>
        <div className="container" style={{ backgroundColor: 'white' }}>
          <div className="header-box" style={{ padding: '20px 0 0 55px' }}>
            <p className="title">Official Notice</p>
          </div>
          <div className="card-row" style={{ padding: '25px 43px 70px 47px' }}>
            {officialCardData.map((card) => (
              <CardDownload
                cardTitle={card.title}
                link={card.link}
                key={card.id}
              />
            ))}
          </div>
        </div>
        <div className="container" style={{ backgroundColor: 'white' }}>
          <div className="header-box" style={{ padding: '20px 0 0 55px' }}>
            <p className="title">AGM Notice</p>
          </div>
          <div className="card-row" style={{ padding: '25px 43px 70px 47px' }}>
            {AGMNotice.map((card) => (
              <CardDownload
                cardTitle={card.title}
                link={card.link}
                key={card.id}
              />
            ))}
          </div>
        </div>
      </section>
    </main>
  )
}

export default Notice
