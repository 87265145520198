import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import mukunda from "../../images/mukunda shrestha.jpg";
import deepak from "../../images/deepak-neupane.jpg";
import shambhuRai from "../../images/shambu-rai.jpg";

export default function ManagerCard() {
  const { id } = useParams();
  const [personDetail, setPersonDetail] = useState();

  useEffect(() => {
    if (id === "grievance-handling-officer") {
      setPersonDetail({
        name: "Shambhu Rai",
        position: "Grievance Handling Officer",
        image: shambhuRai,
        mail: "shambhu.rai@gurkhasfinance.com.np",
        mobileNumber: "014537401 ext : 132",
        address: "Dillibazar, Kathmandu",
        mobile: 9851134982
      });
    }
    if (id === "company-secretary") {
      setPersonDetail(
      //   {
      //   name: "Mukunda Shrestha",
      //   position: "Company Secretary",
      //   image: mukunda,
      //   mail: "mukunda@gurkhasfinance.com.np",
      //   mobileNumber: "014537401",
      //   address: "Dillibazar, Kathmandu",
      // },
      {
        name: "Deepak Neupane",
        position: "Senior Manager",
        image: deepak,
        mail: "deepak.neupane@gurkhasfinance.com.np",
        mobileNumber: "014537401",
        address: "Dillibazar, Kathmandu",
      });
    }
    if (id === "information-officers") {
      setPersonDetail({
        name: "Deepak Neupane",
        position: "Information Officer",
        image: deepak,
        mail: "deepak.neupane@gurkhasfinance.com.np",
        mobileNumber: "014537401",
        address: "Dillibazar, Kathmandu",
      });
    }
  }, [id]);
  return (
    <section className="section-manager-card">
      <div className="container">
        <div className="content" style={{ padding: "25px 30px" }}>
          <div className="officer-card">
            <div className="image-container">
              <img src={personDetail?.image} alt="" />
            </div>
            <div className="text-container">
              <div className="header-box ">
                <p className="caption-big">{personDetail?.name}</p>
                <p className="caption-small">{personDetail?.position}</p>
              </div>
              <div className="sub-header">
                {personDetail?.mail && (
                  <p className="small-light-text">
                    {`Email: ${personDetail.mail}`}{" "}
                  </p>
                )}
                <p className="small-light-text">
                  {personDetail?.mobileNumber &&
                    `Phone : ${personDetail.mobileNumber}`}
                </p>
                <p className="small-light-text">
                  {personDetail?.mobile &&
                    `Mobile : ${personDetail.mobile}`}
                </p>
              </div>
              {personDetail?.address && (
                <p className="small-light-text">{personDetail.address}</p>
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
